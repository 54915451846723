<template>
    <div id="app">
        <!--<div class="header">
            <van-nav-bar title="下载芝富通APP" left-arrow @click-left="goBack">
                <template #left>
                    <van-icon name="arrow-left" size="20" color="#000000"/>
                </template>
            </van-nav-bar>
        </div>
        <div class="content">
            APP介绍图
        </div>

        <div class="footer">
            <van-button type="primary" color="#ef2505" style="width:100%;" block @click="download">立即下载</van-button>
        </div>-->
        <div class="logo">
            <!--<van-icon :name="logo" size="80" ></van-icon>
            <div class="title">芝富通</div>
            <div class="sub-title">ZHIMA PAY</div>-->
            <van-icon :name="logo" size="168" ></van-icon>
        </div>
        <div class="desc">
            <div>如果您未安装请先点击【下载芝富通】按钮</div>
            <div>若无法正常跳转</div>
            <div>请点击右上角，用浏览器打开</div>
        </div>
        <div class="btn">
            <van-button block size="large" @click="download" color="rgb(239,37,5)">下载芝富通</van-button>
        </div>
    </div>
</template>

<script>
    import {
        NavBar,
        Icon,
        Button,
        // Toast
    } from 'vant';
    // import CallApp from "callapp-lib";
    // const options = {
    //     scheme: {
    //         protocol: 'zft',
    //     },
    //     intent: {
    //         package: 'com.zhimadi.zhifutong',
    //         scheme: 'zft',
    //     },
    //     universal: {
    //         host: '574276c97ad34c32ddaab899dcb64557.share2dlink.com',
    //     },
    //     appstore: 'https://apps.apple.com/us/app/id1618707674',
    //     yingyongbao: '//a.app.qq.com/o/simple.jsp?pkgname=com.zhimadi.zhifutong',
    //     fallback: 'https://www.zhimadi.cn/',
    //     timeout: 2000,
    // };
    // const callLib = new CallApp(options);
    export default {
        name: "index.vue",
        data(){
            return{
                logo: require("../../assets/zhifutong_logo.png"),
            }
        },
        components:{
            [NavBar.name]:NavBar,
            [Icon.name]:Icon,
            [Button.name]:Button,
        },
        methods:{
            goBack(){
                this.$router.back()
            },
            download(){

                if(this.is_ios){
                    window.open('//apps.apple.com/cn/app/%E8%8A%9D%E5%AF%8C%E9%80%9A/id1618707674')
                }else{
                    window.open('//a.app.qq.com/o/simple.jsp?pkgname=com.zhimadi.zhifutong')
                }
                // Toast('下载')
                // callLib.open({path:''})
            }
        }
    }
</script>

<style scoped>
    #app{
        height: 100vh;
        background: white;
        text-align: center;
    }
    .logo{
        padding-top: 60px;
    }
    .logo .van-icon{
        border-radius: 10px;
    }
    .title{
        font-size: 24px;
        font-weight: bold;
        margin-top: 8px;
    }
    .sub-title{
        font-size: 12px;
    }
    .desc{
        padding-top: 40px;
        font-size: 14px;
        color: #aaaaaa;
    }
    .btn{
        margin: 40px 30px;
    }
    .btn .van-button{
        font-size: 20px;
    }
    .content{
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #cccccc;
    }
    .footer{
        position: fixed;
        bottom: 0px;
        width: calc(100% - 40px);
        padding: 20px;
    }
</style>
